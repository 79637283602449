import React from "react";
import { Route, Routes as Switch, Navigate } from "react-router-dom";
import Homepage from "./pages/HomePage";
import Signin from "./pages/Signin";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import Signup from "./pages/Signup";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import LandingPage from "./pages/LandingPage";
import ADrawer from "./components/ADrawer";
import Team from "./pages/Team";
import CompleteDashboard from "./pages/CompleteDashboard"
import Adminnn from "./pages/Adminnn";
import AdminTable from "./pages/AdminTable";
import UserManagement from "./pages/UserManagement";
import Settings from "./pages/Settings";
import TeamLeague from "./pages/TeamLeague";
import PreviousCube from "./pages/PreviousCube";
import UleaderBoard from "./pages/UleaderBoard";
import HeadToHead from "./pages/HeadToHead";
import { useAuth } from "./Context/AuthContext";
import LeagueTeamextend from "./pages/LeagueTeamextend";
import EvaluateScore from "./pages/EvaluateScore";
import Tab from "./components/Tab"

function Routes() {
  const { role } = useAuth();

  const theme = createTheme({
    palette: {
      primary: { main: "#1A43C0" },
      white: { main: "#ffff" },
      yellow: { main: 'yellow' }
    }
  });

  return (
    <ThemeProvider theme={theme}>
      <Switch>
        <Route path="/signin" element={<Signin />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/resetpassword" element={<ResetPassword />} />
        <Route path="/" element={<Homepage />} />
        <Route path="/leaderboard" element={<UleaderBoard />} />

        {role === "user" && (
          <>
            <Route path="/landingpage" element={<LandingPage />} />
            <Route path="/headtohead" element={<HeadToHead />} />
            <Route path="/teams" element={<Team />} />
            <Route path="/previousgames" element={<PreviousCube />} />
            <Route path="/" element={<Homepage />} />
            {/* <Route path="/" element={<Tab/>} /> */}
          </>
        )}
        {role === "admin" && (
          <>
            <Route path="/dashboard" element={<CompleteDashboard />} />
            <Route path="/table" element={<AdminTable />} />
            <Route path="/usermanagement" element={<UserManagement />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/scoreboard" element={<EvaluateScore />} />
            <Route path="/leagues" element={<TeamLeague />} />
            <Route path="/leagues&teams" element={<LeagueTeamextend />} />
            <Route path="/grids" element={<Adminnn />} />
          </>
        )}
      </Switch>
    </ThemeProvider>
  );
}

export default Routes;
