import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Tab from "./Tab";
// import AccountCircle from "@mui/icons-material/AccountCircle";
// import Switch from "@mui/material/Switch";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import FormGroup from "@mui/material/FormGroup";
// import MenuItem from "@mui/material/MenuItem";
// import Menu from "@mui/material/Menu";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../images/Logo.png";
import CustomModel from "./CustomModel";
import { Button, Menu, MenuItem } from "@mui/material";
import { useAuth } from "../Context/AuthContext";

export default function NavApp() {
  const [auth, setAuth] = React.useState(true);
  const [isOpen, setIsOpen] = React.useState(false);

  const handleChange = (event) => {
    setAuth(event.target.checked);
  };
  const { token } = useAuth();

  const handleMenu = (event) => {
    setIsOpen(!isOpen);
  };

  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);
  const AddLeagueHandler = () => {
    setOpen(true);
  };

  const termconditionText = () => (
    <>
      <div className="text-justify mt-4">
        <div className="font-bold text-sm">
          Privacy Policy Last updated: June 16, 2024
        </div>
        <br /> This Privacy Policy describes Our policies and procedures on the
        collection, use and disclosure of Your information when You use the
        Service and tells You about Your privacy rights and how the law protects
        You. We use Your Personal data to provide and improve the Service. By
        using the Service, You agree to the collection and use of information in
        accordance with this Privacy Policy. This Privacy Policy has been
        created with the help of the Privacy Policy Generator.
        <br />
        <br />
        <div className="font-bold text-xl">
          Interpretation and Definitions
        </div>{" "}
        <br />
        <div className="font-bold text-sm">Interpretation</div> The words of
        which the initial letter is capitalized have meanings defined under the
        following conditions. The following definitions shall have the same
        meaning regardless of whether they appear in singular or in plural.
        <br />
        <br />
        <div className="font-bold text-sm">Definitions</div> For the purposes of
        this Privacy Policy:
        <br />
        <br />
        <div className="text-justify mx-4">
          <span className="font-bold">Account</span> means a unique account
          created for You to access our Service or parts of our Service.
        </div>
        <div className="text-justify mx-4">
          <span className="font-bold">Affiliate</span> means an entity that
          controls, is controlled by or is under common control with a party,
          where "control" means ownership of 50% or more of the shares, equity
          interest or other securities entitled to vote for election of
          directors or other managing authority.
        </div>{" "}
        <div className="text-justify mx-4">
          <span className="font-bold">Company</span> (referred to as either "the
          Company", "We", "Us" or "Our" in this Agreement) refers to CMM
          Ventures LLC, 204 Mohawk Drive Pittsburgh, PA 15228.
        </div>{" "}
        <div className="text-justify mx-4">
          <span className="font-bold">Cookies </span>are small files that are
          placed on Your computer, mobile device or any other device by a
          website, containing the details of Your browsing history on that
          website among its many uses.{" "}
        </div>
        <div className="text-justify mx-4">
          <span className="font-bold">Country</span> refers to: Pennsylvania,
          United States Device means any device that can access the Service such
          as a computer, a cellphone or a digital tablet.
        </div>
        <div className="text-justify mx-4">
          <span className="font-bold">Personal Data</span> is any information
          that relates to an identified or identifiable individual. Service
          refers to the Website. Service Provider means any natural or legal
          person who processes the data on behalf of the Company.
        </div>
        <div className="text-justify mx-4">
          <span className="font-bold">Service</span> refers to the Website.
        </div>
        <div className="text-justify mx-4">
          <span className="font-bold">Service Provider </span> means any natural
          or legal person who processes the data on behalf of the Company. It
          refers to third-party companies or individuals employed by the Company
          to facilitate the Service, to provide the Service on behalf of the
          Company, to perform services related to the Service or to assist the
          Company in analyzing how the Service is used.
        </div>
        <div className="text-justify mx-4">
          <span className="font-bold">Usage Data </span>refers to data collected
          automatically, either generated by the use of the Service or from the
          Service infrastructure itself (for example, the duration of a page
          visit).
        </div>
        <div className="text-justify mx-4">
          <span className="font-bold">Website</span> refers to SportzCube,
          accessible from http://www.sportzcube.com
        </div>
        <div className="text-justify mx-4">
          <span className="font-bold">You</span> means the individual accessing
          or using the Service, or the company, or other legal entity on behalf
          of which such individual is accessing or using the Service, as
          applicable.
        </div>
        <br />
        <div className="font-bold text-xl">
          Collecting and Using Your Personal Data
        </div>{" "}
        <br />
        <div className="font-bold text-sm">Types of Data Collected</div>
        <br />
        <div className="font-bold text-sm"> Personal Data</div> While using Our
        Service, We may ask You to provide Us with certain personally
        identifiable information that can be used to contact or identify You.
        Personally identifiable information may include, but is not limited to:
        <br />
        <br />
        Email address
        <br />
        Usage Data
        <br />
        <br />
        <div className="font-bold text-sm">Usage Data</div> Usage Data is
        collected automatically when using the Service.
        <br />
        <br />
        Usage Data may include information such as Your Device's Internet
        Protocol address (e.g. IP address), browser type, browser version, the
        pages of our Service that You visit, the time and date of Your visit,
        the time spent on those pages, unique device identifiers and other
        diagnostic data. When You access the Service by or through a mobile
        device, We may collect certain information automatically, including, but
        not limited to, the type of mobile device You use, Your mobile device
        unique ID, the IP address of Your mobile device, Your mobile operating
        system, the type of mobile Internet browser You use, unique device
        identifiers and other diagnostic data. We may also collect information
        that Your browser sends whenever You visit our Service or when You
        access the Service by or through a mobile device.{" "}
        <div className="font-bold text-sm">
          Tracking Technologies and Cookies{" "}
        </div>
        We use Cookies and similar tracking technologies to track the activity
        on Our Service and store certain information. Tracking technologies used
        are beacons, tags, and scripts to collect and track information and to
        improve and analyze Our Service. The technologies We use may include:{" "}
        <br />
        <ol>
          <li>
            <span className="font-bold"> • Cookies or Browser Cookies.</span> A
            cookie is a small file placed on Your Device. You can instruct Your
            browser to refuse all Cookies or to indicate when a Cookie is being
            sent. However, if You do not accept Cookies, You may not be able to
            use some parts of our Service. Unless you have adjusted Your browser
            setting so that it will refuse Cookies, our Service may use Cookies.
          </li>
          <li>
            <span className="font-bold"> • Web Beacons.</span> Certain sections
            of our Service and our emails may contain small electronic files
            known as web beacons (also referred to as clear gifs, pixel tags,
            and single-pixel gifs) that permit the Company, for example, to
            count users who have visited those pages or opened an email and for
            other related website statistics (for example, recording the
            popularity of a certain section and verifying system and server
            integrity).
          </li>
        </ol>
        <br />
        Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies
        remain on Your personal computer or mobile device when You go offline,
        while Session Cookies are deleted as soon as You close Your web browser.
        You can learn more about cookies on TermsFeed website article.
        <br />
        <br />
        We use both Session and Persistent Cookies for the purposes set out
        below:
        <br />
        <br />
        <span className="font-bold">Necessary / Essential Cookies Type:</span>
        <br /> Session Cookies Administered by: Us Purpose: These Cookies are
        essential to provide You with services available through the Website and
        to enable You to use some of its features. They help to authenticate
        users and prevent fraudulent use of user accounts. Without these
        Cookies, the services that You have asked for cannot be provided, and We
        only use these Cookies to provide You with those services.
        <br />
        <br />
        <span className="font-bold">
          {" "}
          Cookies Policy / Notice Acceptance Cookies Type:
        </span>
        <br /> Persistent Cookies Administered by: Us Purpose: These Cookies
        identify if users have accepted the use of cookies on the Website.
        <br />
        <br />
        <span className="font-bold">Functionality Cookies Type:</span>
        <br /> Persistent Cookies Administered by: Us Purpose: These Cookies
        allow us to remember choices You make when You use the Website, such as
        remembering your login details or language preference. The purpose of
        these Cookies is to provide You with a more personal experience and to
        avoid You having to re-enter your preferences every time You use the
        Website.
        <br />
        <br />
        For more information about the cookies we use and your choices regarding
        cookies, please visit our Cookies Policy or the Cookies section of our
        Privacy Policy. Use of Your Personal Data The Company may use Personal
        Data for the following purposes: To provide and maintain our Service,
        including to monitor the usage of our Service. To manage Your Account:
        to manage Your registration as a user of the Service. The Personal Data
        You provide can give You access to different functionalities of the
        Service that are available to You as a registered user. For the
        performance of a contract: the development, compliance and undertaking
        of the purchase contract for the products, items or services You have
        purchased or of any other contract with Us through the Service. To
        contact You: To contact You by email, telephone calls, SMS, or other
        equivalent forms of electronic communication, such as a mobile
        application's push notifications regarding updates or informative
        communications related to the functionalities, products or contracted
        services, including the security updates, when necessary or reasonable
        for their implementation. To provide You with news, special offers and
        general information about other goods, services and events which we
        offer that are similar to those that you have already purchased or
        enquired about unless You have opted not to receive such information. To
        manage Your requests: To attend and manage Your requests to Us.
        <br />
        <br />
        For business transfers: We may use Your information to evaluate or
        conduct a merger, divestiture, restructuring, reorganization,
        dissolution, or other sale or transfer of some or all of Our assets,
        whether as a going concern or as part of bankruptcy, liquidation, or
        similar proceeding, in which Personal Data held by Us about our Service
        users is among the assets transferred. For other purposes: We may use
        Your information for other purposes, such as data analysis, identifying
        usage trends, determining the effectiveness of our promotional campaigns
        and to evaluate and improve our Service, products, services, marketing
        and your experience. We may share Your personal information in the
        following situations: <br />
        <br />
        <span className="font-bold">• With Service Providers:</span> We may
        share Your personal information with Service Providers to monitor and
        analyze the use of our Service, to contact You.
        <br /> <span className="font-bold">• For business transfers:</span> We
        may share or transfer Your personal information in connection with, or
        during negotiations of, any merger, sale of Company assets, financing,
        or acquisition of all or a portion of Our business to another company.
        <br /> <span className="font-bold">• With Affiliates:</span> We may
        share Your information with Our affiliates, in which case we will
        require those affiliates to honor this Privacy Policy. Affiliates
        include Our parent company and any other subsidiaries, joint venture
        partners or other companies that We control or that are under common
        control with Us.
        <br /><span className="font-bold"> • With business partners:</span> We may share Your information with Our
        business partners to offer You certain products, services or promotions.
        <br /><span className="font-bold"> • With other users: </span> when You share personal information or
        otherwise interact in the public areas with other users, such
        information may be viewed by all users and may be publicly distributed
        outside.
        <br /><span className="font-bold"> • With Your consent:</span> We may disclose Your personal information
        for any other purpose with Your consent. Retention of Your Personal Data
        The Company will retain Your Personal Data only for as long as is
        necessary for the purposes set out in this Privacy Policy. We will
        retain and use Your Personal Data to the extent necessary to comply with
        our legal obligations (for example, if we are required to retain your
        data to comply with applicable laws), resolve disputes, and enforce our
        legal agreements and policies. <br />
        <br />
        The Company will also retain Usage Data for internal analysis purposes.
        Usage Data is generally retained for a shorter period of time, except
        when this data is used to strengthen the security or to improve the
        functionality of Our Service, or We are legally obligated to retain this
        data for longer time periods. Transfer of Your Personal Data Your
        information, including Personal Data, is processed at the Company's
        operating offices and in any other places where the parties involved in
        the processing are located. It means that this information may be
        transferred to — and maintained on — computers located outside of Your
        state, province, country or other governmental jurisdiction where the
        data protection laws may differ than those from Your jurisdiction. Your
        consent to this Privacy Policy followed by Your submission of such
        information represents Your agreement to that transfer. The Company will
        take all steps reasonably necessary to ensure that Your data is treated
        securely and in accordance with this Privacy Policy and no transfer of
        Your Personal Data will take place to an organization or a country
        unless there are adequate controls in place including the security of
        Your data and other personal information.
        <br />
        <br />
        Delete Your Personal Data You have the right to delete or request that
        We assist in deleting the Personal Data that We have collected about
        You. Our Service may give You the ability to delete certain information
        about You from within the Service. You may update, amend, or delete Your
        information at any time by signing in to Your Account, if you have one,
        and visiting the account settings section that allows you to manage Your
        personal information. You may also contact Us to request access to,
        correct, or delete any personal information that You have provided to
        Us. Please note, however, that We may need to retain certain information
        when we have a legal obligation or lawful basis to do so. Disclosure of
        Your Personal Data Business Transactions If the Company is involved in a
        merger, acquisition or asset sale, Your Personal Data may be
        transferred. We will provide notice before Your Personal Data is
        transferred and becomes subject to a different Privacy Policy. Law
        enforcement Under certain circumstances, the Company may be required to
        disclose Your Personal Data if required to do so by law or in response
        to valid requests by public authorities (e.g. a court or a government
        agency). <br />
        <br />
        Other legal requirements The Company may disclose Your Personal Data in
        the good faith belief that such action is necessary to: • Comply with a
        legal obligation <br />• Protect and defend the rights or property of
        the Company <br />• Prevent or investigate possible wrongdoing in
        connection with the Service <br />• Protect the personal safety of Users
        of the Service or the public <br />• Protect against legal liability
        Security of Your Personal Data The security of Your Personal Data is
        important to Us, but remember that no method of transmission over the
        Internet, or method of electronic storage is 100% secure. While We
        strive to use commercially acceptable means to protect Your Personal
        Data, We cannot guarantee its absolute security. Children's Privacy Our
        Service does not address anyone under the age of 13. We do not knowingly
        collect personally identifiable information from anyone under the age of
        13. If You are a parent or guardian and You are aware that Your child
        has provided Us with Personal Data, please contact Us. If We become
        aware that We have collected Personal Data from anyone under the age of
        13 without verification of parental consent, We take steps to remove
        that information from Our servers. <br />
        <br />
        If We need to rely on consent as a legal basis for processing Your
        information and Your country requires consent from a parent, We may
        require Your parent's consent before We collect and use that
        information. Links to Other Websites Our Service may contain links to
        other websites that are not operated by Us. If You click on a third
        party link, You will be directed to that third party's site. We strongly
        advise You to review the Privacy Policy of every site You visit. We have
        no control over and assume no responsibility for the content, privacy
        policies or practices of any third party sites or services. Changes to
        this Privacy Policy We may update Our Privacy Policy from time to time.
        We will notify You of any changes by posting the new Privacy Policy on
        this page. We will let You know via email and/or a prominent notice on
        Our Service, prior to the change becoming effective and update the "Last
        updated" date at the top of this Privacy Policy. <br />
        <br />
        You are advised to review this Privacy Policy periodically for any
        changes. <br />
        <br />
        Changes to this Privacy Policy are effective when they are posted on
        this page. <br />
        <br />
        Contact Us If you have any questions about this Privacy Policy, You can
        contact us: <br />
        <br />• By email: Info@sportzcube.com
      </div>
    </>
  );

  const [playOpen, playsetOpen] = React.useState(false);
  const playerHandler = () => {
    playsetOpen(true);
  };

  const conditionText = () => (
    <>
      <div className="text-xl align-baseline">
        <ol style={{ fontSize: "1.1rem" }}>
          {" "}
          <li className="mt-4 mb-4 text-justify">
            • A SportzCube is composed of 6 Position Squares. Each Position
            Square consists of 4 possible player choices.{" "}
          </li>{" "}
          <li className="mt-2 mb-4 text-justify">
            • Select one player for each Position Square that you believe will
            achieve the greatest amount of fantasy points of the 4 available
            choices by the defined cutoff date and time.{" "}
          </li>{" "}
          <li className="mt-2 mb-4 text-justify">
            • You will have one entry and only complete SportzCubes will be
            considered.{" "}
          </li>{" "}
          <li className="mt-2 mb-4 text-justify">
            • A complete SportzCube is defined as one selection from each of the
            6 Positional Squares.{" "}
          </li>{" "}
          <li className="mt-2 mb-4 text-justify">
            • The goal is to pick the highest scoring fantasy player for each
            Positional Square.{" "}
          </li>{" "}
          <li className="mt-2 mb-4 text-justify">
            • Fantasy points will be awarded according to the rules set forth.{" "}
          </li>{" "}
          <li className="mt-2 mb-4 text-justify">
            o In the event of 2 or more players achieving the same fantasy point
            value, Position square rank will be dictated by random selection.{" "}
          </li>{" "}
          <li className="mt-2 mb-4 text-justify">
            • There is a new SportzCube every day as dictated by number of
            available sporting events being played that day. If not enough games
            are scheduled, there will not be a SportzCube that day.{" "}
          </li>{" "}
          <li className="mt-2 mb-4 text-justify">
            o SportzCubes can be comprised of either: Baseball, Football,
            Basketball, or Hockey games yet to be played.{" "}
          </li>{" "}
          <li className="mt-2 mb-4 text-justify">
            • Players may be active or inactive although every effort will be
            made to choose active players.{" "}
          </li>{" "}
          <li className="mt-2 mb-4 text-justify">
            • Once all the games have been completed, each SportzCube will be
            scored for efficiency and rank according to the following formulas.{" "}
          </li>{" "}
          <li className="mt-2 mb-4 text-justify">
            o Cube = (Square+Efficiency)/2{" "}
          </li>{" "}
          <li className="mt-2 mb-4 text-justify">
            Square=sum of achieved position square ranks/6{" "}
          </li>{" "}
          <li className="mt-2 mb-4 text-justify"> Position square rank </li>{" "}
          <li className="mt-2 mb-4 text-justify"> 1=100pts </li>{" "}
          <li className="mt-2 mb-4 text-justify"> 2=75pts </li>{" "}
          <li className="mt-2 mb-4 text-justify"> 3=50pts </li>{" "}
          <li className="mt-2 mb-4 text-justify">4=25pts </li>{" "}
          <li className="mt-2 mb-4 text-justify">
            Efficiency=sum of achieved fantasy points/maximum achievable fantasy
            points
          </li>{" "}
          <li className="mt-2 mb-4 text-justify">
            • A Cube score of 100 indicates a Perfect SportzCube. (Top fantasy
            scorer from each of the 6 Postion Squares.){" "}
          </li>{" "}
          <li className="mt-2 mb-4 text-justify">
            • "Your position on the leaderboard will be dictated by your
            Cumulative Cube Score which is a weighted average.
          </li>{" "}
          <li className="mt-2 mb-4 text-justify">
            Cumulative Cube Score = (sum of all achieved Cube Scores/number of
            times played) X 80% + (most recent Cube Score) X 20%
          </li>{" "}
          <li className="mt-2 mb-4 text-justify">
            o Eligibility for the leaderboard occurs after 10 completed
            SportzCubes{" "}
          </li>{" "}
          <li className="mt-2 mb-4 text-justify">
            o In head to head matches, if 2 or more SportzCube participants
            select the same lineup, rank will be dictated by time SportzCube was
            completed(i.e. which was submitted earliest)
          </li>{" "}
          <li className="mt-2 mb-4 text-center"> Good luck!</li>{" "}
        </ol>
      </div>
    </>
  );

  // const [scoreOpen, scoresetOpen] = React.useState(false);
  // const scoreHandler = () => {
  //   scoresetOpen(true);
  // };

  const [privacyOpen, setPrivacy] = React.useState(false);
  const pHandler = () => {
    setPrivacy(true);
  };

  const privacyHandler = () => (
    <>
      <div className="text-justify mt-4">
        Thank you for visiting this website owned and operated by CMM Ventures
        LLC, its subsidiaries and/or affiliates (collectively, “CMM”). This
        website and all other websites owned or operated by CMM are collectively
        referred to as the “Site.” The following terms and conditions ("Terms
        and Conditions") govern your use of this Site. By accessing, viewing, or
        using the content, material, or services available on or through this
        Site, you indicate that you have read and understand these Terms and
        Conditions, and that you agree to them and intend to be legally bound by
        them. In other words, you are entering into a legally binding agreement
        governed by these Terms and Conditions, and all other related agreements
        and policies, including without limitation the Privacy Policy,
        referenced herein (collectively, the “Agreement”). If you do not agree
        to this Agreement, including the Terms and Conditions, you are not
        granted permission to use this Site and should leave the Site.{" "}
        <div className="text-xl font-bold"> 1. Scope and Acceptance</div> Anyone
        who accesses or uses this Site is an “End User.” This Agreement sets
        forth your rights and obligations as an End User with respect to your
        access and use of the Site and of any and all information or data of any
        kind arising from access or use of the Site, including, but not limited
        to, all statistics, facts, text, graphics, images, forms, artwork, sound
        recordings, audio, video, and software available through the Site. If
        you are accessing or using any part of this Site on behalf of any
        business, organization, or other entity of any kind, you represent and
        warrant that you are authorized (a) to accept this Agreement on that
        entity’s behalf and (b) to bind such entity to the Agreement.
        <div className="font-bold text-xl">2. Proprietary Rights </div> CMM owns
        or licenses the data, statistics, text, graphics, images, forms,
        artwork, sound recordings, audio, video, software, and other material
        for this Site (the "Content"), as well as the selection, coordination,
        arrangement, and organization and enhancement of the Content (also, the
        "Content") for this Site. Except as indicated, this Site and its Content
        are owned or licensed by CMM, and are protected by copyrights,
        trademarks, and/or other intellectual property owned or controlled by
        CMM and/or third-party licensors. Except as otherwise provided for in
        this Agreement, including without limitation paragraphs 5 and 6, all
        rights are reserved.{" "}
        <div className="text-xl font-bold">3. Operation</div> The specific
        features, functionalities, services, and Content of the Site are dynamic
        and may change from time to time. CMM reserves complete discretion with
        respect to the operation of the Site, including what specific features,
        functionalities, services, and Content appear on the Site at any time.
        CMM also reserves the right, in its sole discretion, to withdraw,
        suspend, or discontinue any features, functionalities, services, or
        Content of the Site at any time, with or without notice. CMM makes no
        commitment to update any of the features, functionalities, services, or
        Content appearing on the Site at any time.{" "}
        <div className="text-xl font-bold">4. Inaccuracies</div> CMM makes great
        efforts to provide accurate information on the Site. However, CMM
        disclaims—and you release CMM from any liability regarding—errors,
        inaccuracies, and omissions of the Site. CMM reserves the right to
        correct any errors, inaccuracies, or omissions and to change or update
        information at any time without prior notice. CMM makes no guarantees
        whatsoever as to the completeness, timeliness, correctness, or accuracy
        of the materials or Content available through the Site. Before acting on
        any information appearing on the Site, you should verify its accuracy.
        If you believe any portion of the Site includes an error or inaccuracy,
        please feel free to notify us via email addressed to info@sportzcube.com
        . <div className="text-xl font-bold">5. Permitted Use</div> CMM believes
        in data democratization—open access to data and the tools that use it.
        Moreover, we encourage the sharing and reuse of data and statistics our
        users find on our Site as a general matter, but our business obviously
        would be harmed if a bad apple were to abuse that privilege to create a
        competing statistical database or to copy a materially significant
        portion of our data. So, to strike an appropriate balance between these
        goals, and to protect our business, our technology infrastructure, and
        our ability to continue to provide interesting and robust sports
        statistics to our users, this section of our Agreement sets forth
        certain restrictions on permitted uses of this Site and its Content. Our
        guiding principles are that (1) sharing, using, modifying, repackaging,
        or publishing data found on individual CMM webpages is welcomed, whether
        for commercial or non-commercial purposes, but (2) any such sharing,
        use, modification, repackaging, or publication should explicitly credit
        CMM as the source of the data to the maximum extent possible and (3) any
        such sharing, use, modification, repackaging, or publication must not
        violate any express restrictions set forth in this Section 5, especially
        the restrictions set forth in subparts 5(i) and 5(j) below. Subject to
        the terms of this Agreement, you are granted a limited, personal,
        non-exclusive, non-sublicensable, non-assignable, non-transferable, and
        revocable license to access and use the Site and Content. However,
        except as expressly permitted by this Agreement, in connection with the
        use of the Site, you may not: a. alter or modify the Site; or b. sell,
        rent, lease, transfer, provide, or otherwise assign to any third party
        any rights to the Site; or c. remove or modify any proprietary notice or
        labels on the Site, or use any of our trademarks as meta-tags on any
        other website; or d. use the Site for any non-authorized purpose or any
        illegal purpose; or e. copy, modify, erase, or damage any information
        contained on computer servers used or controlled by SRL or any third
        party; or f. use the Site to violate any legal right of any third party,
        including any publicity or privacy right, copyright, or other
        intellectual property right, or to take any action that is harassing,
        libelous, defamatory, abusive, tortious, threatening, harmful, or
        otherwise objectionable; or g. access or use any password-protected,
        secure, or non-public areas of the Site, or access data on the Site not
        intended for you, except as specifically authorized by SRL; or h.
        impersonate or misrepresent your affiliation with any person or entity;
        or i. without our express written permission, use any automated means to
        access or use the Site, including scripts, bots, scrapers, data miners,
        or similar software, in a manner that adversely impacts site performance
        or access; or j. use any material or Content from the Site, including
        without limitation any statistics or data, (i) to create any database,
        archive, or other data store that competes with or constitutes a
        material substitute for the services or data stores offered on the Site
        or by the Site's Data Providers or (ii) to provide any service that
        competes with or constitutes a material substitute for the services or
        data stores offered on the Site or by the Site's Data Providers; or k.
        copy or use any material or Content from the Site, including without
        limitation any statistics, data, text, graphics, or images, for purposes
        of training, fine-tuning, prompting, or instructing artificial
        intelligence models or technologies in any manner, including without
        limitation for purposes of (i) generating answers, text, scores,
        statistics, notes, graphics, images, or any other output; or (ii)
        supporting machine learning methods used to predict, classify, label, or
        score inputs into the models; or l. attempt to or actually disrupt,
        impair, or interfere with the Site, or any information, data, or
        materials posted and/or displayed by CMM; or m. attempt to probe, scan,
        or test the vulnerability of the Site or breach any implemented security
        or authentication measures, regardless of your motives or intent; or n.
        attempt to interfere with or disrupt access to or use of the Site by any
        user, processor, host, or network, including, without limitation, by
        submitting a virus, worm, Trojan horse, or other malicious code; or o.
        post any content to the Site that: (i) includes any profane, obscene,
        defamatory, discriminatory, threatening, menacing, harassing, or violent
        content; (ii) depicts or suggests nudity or sexual acts; (iii) promotes
        hatred, including against members of a protected group under federal,
        state, or local law (such as, for example, a group defined by race,
        gender, or national origin); (iv) is objectively shocking or disgusting;
        (v) depicts or suggests presently occurring illegal activity, including,
        e.g., illicit drug use or underage drinking; (vi) includes unlicensed
        proprietary content of a third party, including, e.g., third-party
        content protected by copyright or trademark for which you do not have a
        license; (vii) breaches any duty of confidentiality you may have to a
        third party (e.g., discloses private information about a third party
        without consent); or (viii) is contrary to the Agreement, including
        SRL’s rules and policies.{" "}
        <div className="text-xl font-bold">6. Availability of the Website </div>{" "}
        It is not possible to operate our Site with 100% guaranteed uptime. CMM
        will make reasonable efforts to keep our Site operational. However,
        certain technical difficulties, routine site maintenance and upgrades,
        and other events may, from time to time, result in interruptions to or
        outages of our Site. You agree that CMM shall not be liable to you or to
        any third party for any direct or indirect consequence of any
        modification, suspension, discontinuance of, or interruption to our
        Site. <div className="text-xl font-bold"> 7. Privacy Statement </div>CMM
        has a Privacy Statement disclosing what information we collect about
        visitors, how we use such information, and the steps we take to secure
        such information. Click here to view the Privacy Statement, which is
        incorporated into these Terms and Conditions and this Agreement by
        reference, as if set forth fully herein. Please be advised that the
        confidentiality of any communication or material transmitted to CMM via
        the Site or email cannot be guaranteed, including, for example, personal
        information such as your address or name.{" "}
        <div className="text-xl font-bold">
          {" "}
          8. Appropriate Comments and Submissions
        </div>{" "}
        No Spamming CMM asks you to not use vulgarity, profanity or insults in
        your comments. You are free to disagree (even vehemently) with what we
        have to say, but we ask that you do so in a respectful manner. Comments
        will not be deleted for disagreeing with us. They may be removed for the
        above reasons or if the comment is hurtful, spiteful, libelous,
        slanderous or fails to contribute to the ongoing conversation on the
        Site. With respect to email address submitted to the Site, including
        through sponsorships or advertisements, you agree not to harvest or
        compile these email addresses for purposes of spamming or engaging in
        other illicit commercial behavior.{" "}
        <div className="text-xl font-bold">
          9. Disclaimer of Warranty and Liability{" "}
        </div>
        THIS SITE AND THE CONTENT ON AND MADE AVAILABLE THROUGH THIS SITE, AND
        THE SERVICES AND PRODUCTS OFFERED IN CONNECTION THEREWITH ARE MADE
        AVAILABLE ON AN "AS IS" AND AN “AS AVAILABLE” BASIS ONLY. USE OF THIS
        SITE IS ENTIRELY AT YOUR OWN RISK. CMM MAKES NO REPRESENTATIONS OR
        WARRANTIES, AND HEREBY DISCLAIMS ALL REPRESENTATIONS AND WARRANTIES,
        WITH RESPECT TO THIS SITE AND THE CONTENT ON AND MADE AVAILABLE THROUGH
        THIS SITE, AND THE SERVICES AND PRODUCTS OFFERED IN CONNECTION
        THEREWITH, EXPRESS AND IMPLIED, WRITTEN AND ORAL, ARISING FROM COURSE OF
        DEALING, COURSE OF PERFORMANCE, USAGE OF TRADE, AND OTHERWISE,
        INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF
        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, ACCURACY, TITLE,
        QUALITY, SYSTEMS INTEGRATION, AND NON-INFRINGEMENT OF THIRD-PARTY
        RIGHTS. ANY AND ALL INFORMATION PROVIDED BY CMM OR UNDER OR IN
        CONNECTION WITH THIS SITE IS PROVIDED WITH ALL FAULTS, AND THE ENTIRE
        RISK AS TO SATISFACTORY QUALITY, PERFORMANCE, ACCURACY, AND EFFORT IS
        WITH YOU. CMM MAKES NO REPRESENTATION OR WARRANTY THAT ANY MATERIAL OR
        CONTENT DISPLAYED ON OR OFFERED THROUGH THE WEBSITE IS ACCURATE,
        COMPLETE, APPROPRIATE, RELIABLE, OR TIMELY. SRL ALSO MAKES NO
        REPRESENTATIONS OR WARRANTIES THAT THE WEBSITE WILL MEET YOUR
        REQUIREMENTS, OR THAT YOUR ACCESS TO AND USE OF THE WEBSITE WILL BE
        UNINTERRUPTED OR ERROR-FREE, FREE OF VIRUSES, MALICIOUS CODE, OR OTHER
        HARMFUL COMPONENTS, OR OTHERWISE WILL BE SECURE. SOME JURISDICTIONS DO
        NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES. ACCORDINGLY, SOME OF THE
        ABOVE EXCLUSIONS MAY NOT APPLY TO YOU. To the maximum extent permitted
        by applicable law and notwithstanding any other provision of this
        Agreement, CMM shall not be liable for any direct, special, indirect,
        incidental, consequential, exemplary, extra-contractual, or punitive
        damages of any kind whatever, including, without limitation, lost
        revenues or lost profits, which may or do result from the use of, access
        to, or inability to use this Site, the Content, or the products or
        services connected therewith, regardless of legal theory, whether or not
        any party had been advised of the possibility or probability of such
        damages, and even if the remedies otherwise available fail of their
        essential purpose. Under no circumstances will the total liability of
        CMM to you in connection with, based upon, or arising from this Site or
        your use of this Site exceed $100. Some jurisdictions do not permit the
        exclusion or limitation of liability for consequential or incidental
        damages, and, as such, some portion of the above limitation may not
        apply to you. In such jurisdictions, CMM's liability is limited to the
        greatest extent permitted by law.{" "}
        <div className="text-xl font-bold">10. Indemnification</div> You agree
        to defend, indemnify, and hold harmless CMM, its contractors, and all of
        their respective directors, officers, employees, representatives,
        proprietors, partners, shareholders, servants, principals, agents,
        predecessors, successors, assigns, and attorneys from and against any
        and all suits, actions, claims, demands, proceedings, damages,
        settlements, judgments, injuries, liabilities, losses, risks, costs, and
        expenses (including without limitation attorneys' fees and litigation
        expenses) relating to or arising from this Site, your use of this Site,
        your fraud, violation of law, or willful misconduct, and any breach by
        you of this Agreement including these Terms and Conditions.{" "}
        <div className="text-xl font-bold">
          {" "}
          11. Some Services Unavailable Worldwide
        </div>{" "}
        This Site may refer to some services or programs that are not available
        worldwide, without specifically identifying that the offers are
        geographically limited. Our reference to such services or programs does
        not imply that CMM intends to offer such services or programs in all
        countries or locations. The figures and prices displayed on the Site are
        quoted in U.S. dollars.{" "}
        <div className="text-xl font-bold">12. Use of the Internet </div> Use of
        the Internet is solely at your own risk and is subject to all applicable
        local, state, national, and international laws and regulations. Neither
        CMM nor its contractors will be liable for any loss resulting from a
        cause over which they do not have direct control, including without
        limitation failure of electronic or mechanical equipment or
        communication lines, telephone or other interconnect problems, computer
        viruses, unauthorized access, theft, operator errors, severe weather,
        earthquakes, natural disasters, strikes or other labor problems, wars,
        or governmental restrictions.{" "}
        <div className="text-xl font-bold">
          13. Intellectual Property Infringement Complaints
        </div>{" "}
        If you believe that CMM or any user of this Site has infringed your
        copyright, you may notify our copyright agent by emailing
        info@sportzcube.com and providing the following information: a. a
        physical or electronic signature of the person authorized to act on
        behalf of the copyright owner; b. identification of the copyrighted work
        or works claimed to have been infringed; c. a detailed description of
        the material you claim is infringing, together with information
        sufficient to enable us to locate it, including the URL or other
        description of where the infringing material appears; d. your name,
        mailing address, telephone number, and e-mail address; e. a statement by
        you that you believe in good faith that the copyrighted material
        identified is being used in a manner that is not authorized by the
        copyright owner, its agent, or the law; and f. a statement by you that
        the above information is accurate and, under penalty of perjury, that
        you are authorized to act on behalf of the owner of the copyright
        allegedly infringed.{" "}
        <div className="text-xl font-bold">
          14. Changes to and Termination of the Agreement{" "}
        </div>
        This Agreement may be changed by CMM at any time, with or without
        notice, in CMM’s sole discretion. Your continued access, viewing, or use
        of this Site after any change is made to the Agreement signifies your
        understanding, intent, and agreement to be bound by such change. It is
        your responsibility to monitor the Agreement for changes. CMM reserves,
        in its sole discretion, the right to terminate your access to this Site
        in the event that you violate any Terms or Conditions of the Agreement
        or for any reason whatever or no reason, with or without notice.{" "}
        <div className="text-xl font-bold">
          {" "}
          15. Operation of the Website from the United States
        </div>{" "}
        By accessing and using the Site, you acknowledge and agree that CMM
        controls and operates all parts of the Site from its offices in the
        United States and that the Site, and the material and Content contained
        on the Site, is intended for use by End Users located in the United
        States. Other countries may have laws and regulatory requirements that
        differ from those in the United State. Unless expressly stated to the
        contrary, CMM makes no representation that the Site, or the information
        contained on the Site, is appropriate or will be available for use in
        other locations. Unless otherwise explicitly stated, all material and
        Content found on or accessible through the site is solely directed to
        individuals, companies, or other entities located in the United States.
        CMM reserves the right to limit, in its sole discretion, the provision
        and quantity of any feature, functionality, product, or service to any
        person or geographic area. Any offer for any feature, functionality,
        product, or service made on or through the Site is void where
        prohibited. If you access or use the Site from outside the United
        States, you are entirely responsible for compliance with applicable
        local laws and other applicable laws. You may not use any portion of the
        Site in violation of applicable export laws and regulations. If you
        access the Site from outside the United States, you acknowledge and
        agree that your information may be transferred to and maintained on
        computers and servers located outside of your state, province, country,
        or other governmental jurisdiction where the privacy laws may not be as
        protective as those in your jurisdiction. Your consent to the Agreement
        followed by your submission of such information represents your
        agreement to the transfer of such information to the United States and
        the collection, use, and disclosure of your information in accordance
        with United States law.{" "}
        <div className="text-xl font-bold">
          {" "}
          16. Unsolicited Materials and End User’s Grant of Limited Licensed{" "}
        </div>
        In operating our Site, CMM does not solicit nor does it wish to receive
        any confidential, secret, or proprietary information or other material
        from you through the Site, our mail and email addresses, or in any other
        way. Any information or material submitted or sent to CMM (including
        without limitation comments, questions, feedback, data, suggestions,
        ideas, and the like) will be deemed not confidential or secret. By
        submitting or sending information or other material to CMM, you
        represent and warrant that the information is original to you and that
        no other party has any rights to the material. By communicating with
        CMM, including submitting or sending content to us, you grant CMM the
        royalty-free, unrestricted, world-wide, perpetual, irrevocable,
        non-exclusive, and fully sublicensable right and license to use,
        reproduce, modify, adapt, publish, translate, create derivative works
        from, distribute, perform and display such content (in whole or part),
        including any information, suggestions, ideas, drawings or concepts
        contained in such content, worldwide and/or to incorporate it in other
        works in any form, media, or technology now known or later developed.
        Such license includes incorporating the content, in whole or in part,
        into an CMM feature. You will remain responsible for the accuracy,
        copyright compliance, legality, decency, or any other aspect of such
        submitted material, including any content or part thereof, or other
        communication to CMM. You also warrant that any "moral rights" in such
        content is waived.{" "}
        <div className="text-xl font-bold">17. Dispute Resolution</div> CMM
        intends to resolve all disputes that may arise between it and its End
        Users in a cost-effective and non-disruptive manner, preferably without
        the time and expense of litigation. Toward this end, you agree to the
        following dispute resolution procedure. If you are unable to resolve any
        dispute in the ordinary course of business, you shall send a written
        notice to CMM in which you outline the issues in dispute, enclose any
        relevant documents, and state the requested relief. CMM shall respond
        within 10 business days with identical information from its perspective.
        You and a representative of CMM shall meet or communicate electronically
        within 10 business days of the delivery of the response, and as often as
        you and CMM mutually deem necessary or desirable thereafter, in an
        attempt to resolve the matter. If, within 60 days of the first
        communication, you and CMM fail to resolve the matter, you and CMM shall
        seek to mediate the dispute with a neutral third party. Neither party
        shall seek further legal action unless such mediation fails to achieve a
        resolution acceptable to both parties. You agree that you will not bring
        or be a party to any class-action lawsuit against CMM.{" "}
        <div className="text-xl font-bold">
          18. Entire Agreement; Governing Law and Jurisdiction{" "}
        </div>{" "}
        This Agreement, including the Terms and Conditions, represents the
        entire agreement between you and CMM with respect to the subject matter
        hereof and supersedes any and all prior and contemporaneous written and
        oral representations, understandings, and agreements between us. This
        Agreement will be governed by and construed in accordance with the laws
        of the Commonwealth of Pennsylvania without reference to its conflict of
        law rules. You agree and consent to the jurisdiction of the federal and
        state courts presiding in the Commonwealth of Pennsylvania, and agree to
        accept service of process by mail and hereby waive any and all
        jurisdictional and venue defenses otherwise available.{" "}
        <div className="text-xl font-bold"> 19. Miscellaneous</div>
        If any part of the Agreement is determined to be invalid or
        unenforceable pursuant to applicable law including, but not limited to,
        the warranty disclaimers and liability limitations set forth above, then
        the invalid or unenforceable provision will be deemed superseded by a
        valid, enforceable provision that most closely matches the intent of the
        original provision and the remainder of the Agreement shall continue in
        effect. No failure or delay in enforcing any provision, exercising any
        option, or requiring performance, shall be construed to be a waiver of
        that or any other right in connection with the Agreement. No action
        arising out of this Agreement or your access to or use of our Site,
        regardless of form or the basis of the claim, may be brought by you more
        than one year after the cause of action has arisen (or if multiple
        causes, from the date the first such cause arose) and you hereby waive
        any longer statute of limitations that may be permitted by law. If CMM
        does take any legal action against you as a result of your violation of
        the Agreement, CMM will be entitled to recover from you, and you agree
        to pay, all reasonable attorneys’ fees and costs of such action, in
        addition to any other relief granted to CMM. You agree that CMM will not
        be liable to you or to any third party for termination of your access
        to, or use of, any of our Site as a result of any violation of the
        Agreement or for any reason at all. You may not assign, by operation of
        law or otherwise, any rights or delegate any duties under the Agreement
        to any third party without prior written consent by CMM. Any purported
        assignment lacking such consent will be void at its inception. CMM may
        assign all or part of its rights and/or delegate all or part of its
        duties under the Agreement to any party, at any time, and in its sole
        discretion, upon notice of assignment by posting such notice on our
        Site. Effective Date Effective Date: February 18, 2024
      </div>
    </>
  );

  // const scoringText = () => (
  //   <>
  //     <div className="text-xl align-baseline">
  //       <ol style={{ fontSize: "1.1rem" }}>
  //         {" "}
  //         <li className="mt-2 mb-4 text-justify">
  //           • Once all the games have been completed, each SportzCube will be
  //           scored for efficiency and rank according to the following formulas.{" "}
  //         </li>{" "}
  //         <li className="mt-2 mb-4 text-justify">
  //           o Cube = (Square+Efficiency)/2{" "}
  //         </li>{" "}
  //         <li className="mt-2 mb-4 text-justify">
  //           Square=sum of achieved position square ranks/6{" "}
  //         </li>{" "}
  //         <li className="mt-2 mb-4 text-justify"> Position square rank </li>{" "}
  //         <li className="mt-2 mb-4 text-justify"> 1=100pts </li>{" "}
  //         <li className="mt-2 mb-4 text-justify"> 2=75pts </li>{" "}
  //         <li className="mt-2 mb-4 text-justify"> 3=50pts </li>{" "}
  //         <li className="mt-2 mb-4 text-justify">4=25pts </li>{" "}
  //         <li className="mt-2 mb-4 text-justify">
  //           Efficiency=sum of achieved fantasy points/maximum achievable fantasy
  //           points
  //         </li>{" "}
  //         <li className="mt-2 mb-4 text-justify">
  //           • A Cube score of 100 indicates a Perfect SportzCube. (Top fantasy
  //           scorer from each of the 6 Postion Squares.){" "}
  //         </li>{" "}
  //         <li className="mt-2 mb-4 text-justify">
  //           • Your position on the leaderboard will be dictated by your
  //           cumulative Cube score = sum of all achieved Cube scores/number of
  //           times played.
  //         </li>{" "}
  //         <li className="mt-2 mb-4 text-justify">
  //           o Eligibility for the leaderboard occurs after 10 completed
  //           SportzCubes{" "}
  //         </li>{" "}
  //         <li className="mt-2 mb-4 text-justify">
  //           o In head to head matches, if 2 or more SportzCube participants
  //           select the same lineup, rank will be dictated by time SportzCube was
  //           completed(i.e. which was submitted earliest)
  //         </li>{" "}
  //         <li className="mt-2 mb-4 text-center"> Good luck!</li>{" "}
  //       </ol>
  //     </div>
  //   </>
  // );

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [helpAnchorEl, setHelpAnchorEl] = React.useState(null);
  const helpMenuOpen = Boolean(helpAnchorEl);

  // Event handler for opening the Help menu
  const handleHelpMenuOpen = (event) => {
    setHelpAnchorEl(event.currentTarget);
  };

  // Event handler for closing the Help menu
  const handleHelpMenuClose = () => {
    setHelpAnchorEl(null);
  };

  

   const [newOpen, newsetOpen] = React.useState(false);
  const LeagueHandler = () => {
   return navigate("/leaderboard");
    // newsetOpen(true);
  }

 const LeaHandler = () => {
   if (token) return navigate("/previousgames");
   newsetOpen(true);
  };
  
  const [privacy, setPrivacyH] = React.useState("");
  const policyHandler = () => {
    setPrivacyH(true);
  };

  return (
    <>
      <CustomModel
        buttonHide={true}
        open={playOpen}
        variant={"TermsandConditions"}
        setOpen={playsetOpen}
        termconditionText={termconditionText}
        headingText={"Privacy Policy"}
      />

      <CustomModel
        buttonHide={false}
        open={open}
        variant={"text"}
        setOpen={setOpen}
        // innerText={innerText}
        conditionText={conditionText}
        headingText={"How to play SPORTZCUBE"}
      />

      <CustomModel
        buttonHide={false}
        open={privacy}
        variant={"scoring"}
        setOpen={setPrivacyH}
        // innerText={innerText}

        headingText={"Scoring rules"}
      />

      <CustomModel
        buttonHide={false}
        open={privacyOpen}
        variant={"privacy"}
        setOpen={setPrivacy}
        // innerText={innerText}
        privacyHandler={privacyHandler}
        headingText={"Terms & Conditions"}
      />

      <CustomModel
        buttonHide={false}
        open={newOpen}
        variant={"popup"}
        setOpen={newsetOpen}
      />

      <Box sx={{ flexGrow: 1 }}>
        <div position="static" className="bg-[#082f78] ">
          <Toolbar>
            {/* Show on small screens and hide on large screens */}
            <div className="">
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
              >
                <img
                  src={Logo}
                  alt="Logo"
                  style={{ width: "70px", height: "70px" }}
                />
                <span className="text-sm text-white"> So easy, it's hard!</span>
              </IconButton>
            </div>
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1 }}
            ></Typography>
            {auth && (
              <div className="flex">
                {/* Show on large screens and hide on small screens */}
                <div className=" lg:hidden ">
                  <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    color="inherit"
                  >
                    <MenuIcon color="white" />
                  </IconButton>
                </div>
                <div className="flex float-end text-white pt-4">
                  <div
                    className="hidden sm:block md:block cursor-pointer mr-5 text-lg"
                    onClick={() => {
                      navigate("/");
                    }}
                  >
                    Home
                  </div>
                  <div
                    className="hidden sm:block  cursor-pointer mr-5 text-lg "
                    onClick={() => {
                      LeaHandler();
                    }}
                  >
                    Previous Cubes
                  </div>
                  <div
                    className="hidden sm:block mr-5 cursor-pointer text-lg"
                    onClick={() => {
                      LeagueHandler();
                    }}
                  >
                    Leaderboard
                  </div>
                  <div
                    className="hidden sm:block mr-5 cursor-pointer text-lg"
                    onClick={() => {
                      AddLeagueHandler();
                    }}
                  >
                    How to play
                  </div>
                  <div
                    className="hidden sm:block mr-5 cursor-pointer text-lg"
                    onClick={() => {
                      pHandler();
                    }}
                  >
                    Terms & Conditions
                  </div>
                  <div
                    className="hidden sm:block mr-5 cursor-pointer text-lg"
                    onClick={() => {
                      playerHandler();
                    }}
                  >
                    Privacy Policy
                  </div>
                  <div
                    className="hidden sm:block mr-4 cursor-pointer text-lg "
                    onClick={() => {
                      policyHandler();
                    }}
                  >
                    Scoring Rules
                  </div>

                  <div className="hidden sm:block  cursor-pointer text-justify pb-2">
                    {!token ? (
                      <Button
                        id="basic-button"
                        // sx={{ color: "white" }}
                        style={{ color: "white", fontSize: "0.95rem" }}
                        onClick={() => {
                          localStorage.clear();
                          navigate("/signin");
                        }}
                      >
                        Login
                      </Button>
                    ) : (
                      <Button
                        id="basic-button"
                        // sx={{ color: "white" }}
                        style={{ color: "white", fontSize: "0.95rem" }}
                        onClick={() => {
                          localStorage.clear();
                          navigate("/signin");
                        }}
                      >
                        Logout
                      </Button>
                    )}
                    {/* <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={openMenu}
                      onClose={handleClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      <MenuItem
                        onClick={() => {
                          navigate("/signin");
                        }}
                      >
                        Login as User
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          navigate("/signin");
                        }}
                      >
                        Login as Admin
                      </MenuItem>
                    </Menu> */}
                  </div>
                </div>
              </div>
            )}
          </Toolbar>
        </div>
        {isOpen === true && (
          <div className="absolute bg-[#10368D] min-h-[150vh] w-full z-[100] text-white text-2xl px-3 py-3">
            <ul>
              <li>
                <div
                  onClick={() => {
                    navigate("/");
                  }}
                  className="cursor-pointer"
                >
                  {" "}
                  Home
                </div>
              </li>
              <hr className="my-3" />
              <li>
                <div
                  onClick={() => {
                    LeaHandler();
                  }}
                  className="cursor-pointer"
                >
                  Previous Cubes
                </div>
              </li>
              <hr className="my-3" />
              <li>
                <div
                  onClick={() => {
                    LeagueHandler();
                  }}
                  className="cursor-pointer"
                >
                  Leaderboard
                </div>
              </li>
              <hr className="my-3" />
              <li>
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    AddLeagueHandler();
                  }}
                >
                  How to play
                </div>
              </li>
              <hr className="my-3" />
              <li>
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    pHandler();
                  }}
                >
                  Terms & Condition
                </div>
              </li>
              <hr className="my-3" />
              <li>
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    playerHandler();
                  }}
                >
                  Privacy Policy
                </div>
              </li>
              <hr className="my-3" />
              <li>
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    policyHandler();
                  }}
                >
                  Scoring Rules
                </div>
              </li>

              <hr className="my-3 " />
              <li>
                {!token ? (
                  <div
                    className="cursor-pointer"
                    id="basic-button"
                    // sx={{ color: "white" }}
                    style={{ color: "white" }}
                    onClick={() => {
                      localStorage.clear();
                      navigate("/signin");
                    }}
                  >
                    Login
                  </div>
                ) : (
                    <div
                      className="cursor-pointer"
                    id="basic-button"
                    // sx={{ color: "white" }}
                    style={{ color: "white" }}
                    onClick={() => {
                      localStorage.clear();
                      navigate("/signin");
                    }}
                  >
                    Logout
                  </div>
                )}
              </li>
            </ul>
          </div>
        )}
      </Box>
    </>
  );
}
